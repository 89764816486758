<template>
  <div class="tablePrint">
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :md="12" :lg="12" :xl="12">
          {{ $t("message.procedural") }}
        </el-col>
        <el-col :xs="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="printDouble(patientMedicine.id)">{{
                $t("message.print_double")
              }}</el-button>
              <el-button @click="print(patientMedicine.id)">{{
                $t("message.print")
              }}</el-button>
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12">
        <el-card class="box-card mt-2">
          <el-collapse class="open_cliet_hstori mb-4" v-model="activeCollapse">
            <el-collapse-item :title="$t('message.patient_info')" name="1">
              <el-row>
                <el-col :span="6">
                  {{ $t("message.fio") }}:
                  <b>{{
                    patientMedicine.patient
                      ? patientMedicine.patient.surname +
                        " " +
                        patientMedicine.patient.first_name
                      : " "
                  }}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.phone") }}:
                  <b>{{
                    patientMedicine.patient
                      ? patientMedicine.patient.phone
                      : " " | formatPhoneNumber
                  }}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.dob") }}:
                  <b>{{
                    patientMedicine.patient
                      ? patientMedicine.patient.born_date
                      : " "
                  }}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.gender") }}:
                  <b>{{
                    patientMedicine.patient
                      ? $t(`message.${patientMedicine.patient.gender}`)
                      : " "
                  }}</b>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>

          <el-collapse
            class="open_cliet_hstori pt-0 mb-4"
            v-model="activeCollapse2"
          >
            <el-collapse-item :title="$t('message.order_service')" name="1">
              <ul class="ul_usluga">
                <li v-for="(item, i) in order_services" :key="i">
                  <div>-{{ item.name }}</div>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </div>
    </div>
    <div class="center__block_form">
      <el-card class="box-card m-4">
        <div>
          <div>
            <span slot="label"
              ><b> {{ $t("message.products") }}</b></span
            >
          </div>
          <table
            class="table table-bordered table-hover"
            v-loading="loadingData"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("message.medicine") }}</th>
                <th>{{ $t("message.quantity") }}</th>
                <th>{{ $t("message.price") }}</th>
                <th>{{ $t("message.warehouse") }}</th>
                <th>{{ $t("message.total") }}</th>
              </tr>
            </thead>
            <!-- {{patientMedicine.items[0]}} -->
            <transition-group name="flip-list" tag="tbody">
              <tr v-for="item in filterValid(items)" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.product.name }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.product.price | formatMoney }}</td>
                <td>{{ item.warehouse.name }}</td>
                <td>{{ item.quantity * item.product.price | formatMoney }}</td>
              </tr>
            </transition-group>
          </table>
        </div>
      </el-card>

      <el-card class="box-card m-4">
        <div>
          <div>
            <span slot="label"
              ><b> {{ $t("message.invalid") }}</b></span
            >
          </div>
          <table
            class="table table-bordered table-hover"
            v-loading="loadingData"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("message.medicine") }}</th>
                <th>{{ $t("message.quantity") }}</th>
                <th>{{ $t("message.price") }}</th>
                <th>{{ $t("message.warehouse") }}</th>
                <th>{{ $t("message.total") }}</th>
                <th>{{ $t("message.comment") }}</th>
              </tr>
            </thead>
            <!-- {{patientMedicine.items[0]}} -->
            <transition-group name="flip-list" tag="tbody">
              <tr v-for="item in filterInvalid(items)" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.product.name }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.product.price | formatMoney }}</td>
                <td>{{ item.warehouse.name }}</td>
                <td>{{ item.quantity * item.product.price | formatMoney }}</td>
                <td>{{ item.comment }}</td>
              </tr>
            </transition-group>
          </table>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import { i18n } from "@/utils/i18n";
import {formatMoney, formatPhoneNumber} from "@/filters";

export default {
  mixins: [drawerChild, form],
  props: ["selected"],

  data() {
    return {
      loadingData: false,
      activeCollapse: "0",
      activeCollapse2: "0",
      order_services: null,
      items: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    ...mapGetters({
      patientMedicine: "patientMedicine/model",
      rules: "conclusions/rules",
      model: "conclusions/model",
      order_model: "orders/relation_model",
      columns: "conclusions/columns",
    }),
  },
  async mounted() {},
  methods: {
    formatMoney,
    formatPhoneNumber,
    ...mapActions({
      showModel: "patientMedicine/patientShow",
      doctorTemplate: "formTemplate/doctorTemplate",
      doctorTemplates: "formTemplate/doctorTemplates",
    }),
    afterOpened() {
      this.showModel(this.selected)
        .then((res) => {
          this.order_services = res.data.patient_medicine.order.order_services;
          this.items = res.data.patient_medicine.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterInvalid(item) {
      var data = item != [] ? item.filter((val) => val.is_invalid == true) : [];
      return data;
    },
    filterValid(item) {
      var data =
        item != [] ? item.filter((val) => val.is_invalid == false) : [];
      return data;
    },
    print(id) {
      this.waitingPrint = true;
      let vm = this;
      var jqxhr = $.get(
        process.env.VUE_APP_URL + `orders/print-patient-medicine?id=${id}`,
        function () {}
      )
        .done(function (res) {
          vm.waitingPrint = false;
          const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
          );
          WinPrint.document.write(res);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        })
        .fail(function () {
          alert(i18n.t("message.cannot_print"));
          vm.waitingPrint = false;
        });
    },
    printDouble(id) {
      this.waitingPrint = true;
      let vm = this;
      var jqxhr = $.get(
        process.env.VUE_APP_URL +
          `orders/print-patient-medicine-double?id=${id}`,
        function () {}
      )
        .done(function (res) {
          vm.waitingPrint = false;
          const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
          );
          WinPrint.document.write(res);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        })
        .fail(function () {
          alert(i18n.t("message.cannot_print"));
          vm.waitingPrint = false;
        });
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "reopenShow" });
    },
    afterClosed() {
      this.clear();
    },
    clear() {
      this.order_services = [];
      this.items = [];
    },
  },
};
</script>